import React, { useState } from 'react';

import { Button, Modal, Message } from 'semantic';
import { useTranslation } from 'react-i18next';

import { request } from 'utils/api';
import {
  EvseController,
  EvseControllerAuthenticationMethod,
  EvseControllerConnectionProtocol,
  EvseControllerConnectionType,
} from 'types/evse-controller';
import { SelectableButton } from 'components/SelectableButton';

type Props = {
  evseController: EvseController;
};

export default function SecurityProfileModal({ evseController }: Props) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  let defaultSelectedProfile = 0; // security profile 0 (or None) is the default
  if (
    evseController.ocppAuthorizationMethod ===
    EvseControllerAuthenticationMethod.Basic
  ) {
    if (
      evseController.connectionProtocol === EvseControllerConnectionProtocol.Ws
    ) {
      defaultSelectedProfile = 1; // security profile 1 is the default
    } else {
      defaultSelectedProfile = 2; // security profile 2 is the default
    }
  }

  const [selectedProfile, setSelectedProfile] = useState<number>(
    defaultSelectedProfile
  );
  const [
    updateEvseControllerAuthenticationLoading,
    setUpdateEvseControllerAuthenticationLoading,
  ] = useState(false);
  const [
    updateEvseControllerAuthenticationError,
    setUpdateEvseControllerAuthenticationError,
  ] = useState<Error | null>(null);

  const updateEvseControllerAuthentication = async (
    authenticationMethod: EvseControllerAuthenticationMethod
  ) => {
    setUpdateEvseControllerAuthenticationLoading(true);
    setUpdateEvseControllerAuthenticationError(null);
    try {
      await request({
        method: 'PATCH',
        path: `/1/evse-controllers/${evseController.id}/authentication`,
        body: { authentication: authenticationMethod },
      });
      setModalOpen(false);
    } catch (error) {
      setUpdateEvseControllerAuthenticationError(error as Error);
    } finally {
      setUpdateEvseControllerAuthenticationLoading(false);
    }
  };

  const onClickUpdateSecurityProfile = async () => {
    if (selectedProfile === defaultSelectedProfile) {
      return setModalOpen(false);
    }

    if (selectedProfile === 0) {
      return updateEvseControllerAuthentication(
        EvseControllerAuthenticationMethod.None
      );
    }

    return updateEvseControllerAuthentication(
      EvseControllerAuthenticationMethod.Basic
    );
  };

  const disableSecurityProfile1Option =
    evseController.connectionProtocol !== EvseControllerConnectionProtocol.Ws ||
    evseController.connectionType !== EvseControllerConnectionType.Private;
  const disableSecurityProfile2Option =
    evseController.connectionProtocol !== EvseControllerConnectionProtocol.Wss;

  return (
    <Modal
      closeIcon
      open={modalOpen}
      onOpen={() => setModalOpen(true)}
      onClose={() => setModalOpen(false)}
      size="small"
      trigger={
        <Button
          primary
          icon="lock"
          content={t(
            'securityProfileModal.openSecurityProfileButton',
            'Security Profile'
          )}
        />
      }>
      <Modal.Header>
        {t('securityProfileModal.title', 'Security Profile')}
      </Modal.Header>
      <Modal.Content scrolling>
        {updateEvseControllerAuthenticationError && (
          <Message
            error
            content={updateEvseControllerAuthenticationError.message}
          />
        )}
        <p>
          {t(
            'securityProfileModal.description',
            'To ensure best practices are followed regarding secure connectivity of your charging station we recommend that you enable one of the standard OCPP Security Profile options.'
          )}
        </p>
        <h4>
          {t(
            'securityProfileModal.selectSecurityProfile',
            'Select security profile'
          )}
        </h4>
        <div style={{ marginBottom: 20 }}>
          <Option
            title={t('securityProfileModal.optionNoneTitle', 'None')}
            description={t(
              'securityProfileModal.optionNoneDescription',
              'No security profile is set.'
            )}
            note={t(
              'securityProfileModal.optionNoneNote',
              'Connection not authenticated.'
            )}
            onClick={() => setSelectedProfile(0)}
            selected={selectedProfile === 0}
          />
          <Option
            title={t(
              'securityProfileModal.optionSecurityProfile1Title',
              'Security Profile 1'
            )}
            description={t(
              'securityProfileModal.optionSecurityProfile1Description',
              'Security profile 1 with Basic Authentication'
            )}
            note={t(
              'securityProfileModal.optionSecurityProfile1Note',
              'Use for VPN connected stations - connected to ws.'
            )}
            onClick={() => setSelectedProfile(1)}
            selected={selectedProfile === 1}
            disabled={disableSecurityProfile1Option}
          />
          <Option
            title={t(
              'securityProfileModal.optionSecurityProfile2Title',
              'Security Profile 2'
            )}
            description={t(
              'securityProfileModal.optionSecurityProfile2Description',
              'Security profile 2 with Basic Authentication'
            )}
            note={t(
              'securityProfileModal.optionSecurityProfile2Note',
              'Use for stations connected using SSL/TLS - connected to wss.'
            )}
            onClick={() => setSelectedProfile(2)}
            selected={selectedProfile === 2}
            disabled={disableSecurityProfile2Option}
          />
        </div>
        <strong>
          {t(
            'securityProfileModal.note',
            'Enabling a security profile will set a password on the charge station which is used to connect and enforced by the OCPP server.'
          )}
        </strong>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          onClick={() => setModalOpen(false)}
          content={t('securityProfileModal.cancel', 'Cancel')}
        />
        <Button
          primary
          loading={updateEvseControllerAuthenticationLoading}
          onClick={() => onClickUpdateSecurityProfile()}
          content={t(
            'securityProfileModal.updateSecurityProfileButton',
            'Update Security Profile'
          )}
        />
      </Modal.Actions>
    </Modal>
  );
}

const Option = ({
  title,
  description,
  note,
  onClick,
  selected,
  disabled,
}: {
  title: string;
  description: string;
  note: string;
  onClick: () => void;
  selected: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginBottom: 20 }}>
      <SelectableButton
        selected={selected}
        onClick={onClick}
        disabled={disabled}>
        <div style={{ textAlign: 'left', marginTop: 8, marginBottom: 8 }}>
          <p style={{ marginBottom: 6 }}>
            <strong>{title}</strong>
          </p>
          <p style={{ marginBottom: 6 }}>{description}</p>
          <p style={{ marginBottom: 0 }}>
            <i>{note}</i>
          </p>
        </div>
      </SelectableButton>
    </div>
  );
};
