import React from 'react';
import { Button, Form, Message, Modal } from 'semantic';
import { TFunction, useTranslation } from 'react-i18next';
import { LabelItem } from '.';
import { joiErrorDetailsToObject, request } from 'utils/api';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../components/form-fields/formik/InputField';
import { Grid } from 'semantic-ui-react';

interface EvseControllerLabelActionsProps {
  item: LabelItem;
  writeAccess: boolean;
  t: TFunction;
  onSave: () => void;
}

export default function EvseControllerLabelActions({
  item,
  writeAccess,
  t,
  onSave,
}: EvseControllerLabelActionsProps) {
  const handleDeleteLabel = (item: LabelItem) => {
    return request({
      method: 'DELETE',
      path: `/1/evse-controllers/labels/${item.label}`,
    }).then(() => {
      onSave();
    });
  };

  const handleRenameLabel = (item: LabelItem) => {
    return (formValue: { newLabel: string }, formikBag: any) => {
      return request({
        method: 'PATCH',
        path: `/1/evse-controllers/labels`,
        body: {
          oldLabel: item.label,
          newLabel: formValue.newLabel,
        },
      })
        .then(() => {
          formikBag.resetForm();
          onSave();
        })
        .catch((error: any) => {
          if (Array.isArray(error?.details)) {
            formikBag.setErrors(joiErrorDetailsToObject(error));
            return;
          }

          if (error?.status === 409) {
            formikBag.setStatus(
              t(
                'manageLabels.renameModal.error.duplicate',
                'Label "{{label}}" already exists.',
                {
                  label: formValue.newLabel,
                }
              )
            );
            return;
          }

          formikBag.setStatus(error?.message);
        });
    };
  };

  return (
    <>
      <RenameLabel
        writeAccess={writeAccess}
        onSubmitHandler={handleRenameLabel}
        item={item}
      />

      <DeleteLabel
        writeAccess={writeAccess}
        onSubmitHandler={handleDeleteLabel}
        item={item}
      />
    </>
  );
}

interface ActionModalProps {
  writeAccess: boolean;
  onSubmitHandler: any;
  item: LabelItem;
}

function RenameLabel({ writeAccess, onSubmitHandler, item }: ActionModalProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation();

  const VALIDATION_SCHEMA = Yup.object({
    newLabel: Yup.string().required(),
  });

  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={onSubmitHandler(item, setIsModalOpen)}
        validationSchema={VALIDATION_SCHEMA}
        initialValues={{
          newLabel: item.label,
        }}>
        {({ values, dirty, status, isSubmitting, handleSubmit, isValid }) => {
          return (
            <Modal
              closeIcon
              open={isModalOpen}
              onOpen={() => setIsModalOpen(true)}
              onClose={() => {
                setIsModalOpen(false);
                values.newLabel = '';
              }}
              trigger={
                <Button
                  disabled={!writeAccess}
                  basic
                  icon="pen-to-square"
                  title="Rename"
                />
              }>
              <Modal.Header>
                {t(
                  'manageLabels.renameModal.title',
                  'Rename Label "{{label}}"',
                  {
                    label: item.label,
                  }
                )}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <InputField
                    name={`newLabel`}
                    label={t('manageLabels.renameModal.title', 'New Label')}
                    type="text"
                    min={0}
                  />
                </Form>
                {status && (
                  <Message error>
                    <p>{status}</p>
                  </Message>
                )}
                <Message
                  info
                  content={t(
                    'manageLabels.renameModal.info',
                    'Please ensure that your label name is unique and does not exactly match any existing labels. '
                  )}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  basic
                  content={t('common.close', 'Close')}
                  onClick={() => {
                    setIsModalOpen(false);
                    values.newLabel = '';
                  }}
                />
                <Button
                  as="button"
                  loading={isSubmitting}
                  role="button"
                  disabled={!writeAccess || isSubmitting || !isValid || !dirty}
                  primary
                  content={t('common.save', 'Save')}
                  onClick={handleSubmit}
                />
              </Modal.Actions>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
}

function DeleteLabel({ writeAccess, onSubmitHandler, item }: ActionModalProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { t } = useTranslation();

  return (
    <Modal
      closeIcon
      open={isModalOpen}
      onOpen={() => setIsModalOpen(true)}
      onClose={() => setIsModalOpen(false)}
      trigger={
        <Button disabled={!writeAccess} basic icon="trash" title="Delete" />
      }>
      <Modal.Header>
        {t('manageLabels.deleteModal.title', 'Delete Label')}
      </Modal.Header>
      <Modal.Content style={{ paddingTop: '0' }}>
        <h4>
          {t('manageLabels.deleteModal.heading', 'Delete label "{{label}}"?', {
            label: item.label,
          })}
        </h4>
        <p>
          {item.usage > 0
            ? t(
                'manageLabels.deleteModal.warning.withUsage',
                'This label will be removed from {{usage}} charging stations.',
                {
                  usage: item.usage,
                }
              )
            : t(
                'manageLabels.deleteModal.warning.withoutUsage',
                'This label is not used by any charging stations.'
              )}
        </p>
        <p>
          {t(
            'manageLabels.deleteModal.warning.description',
            'This action cannot be undone.'
          )}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} textAlign="left" verticalAlign="middle">
              <Link
                to={`/charging-stations?labels=${item.label}`}
                target="_blank"
                title={t(
                  'manageLabels.deleteModal.viewAssignedStation',
                  'View assigned stations'
                )}
                style={{
                  marginRight: 'auto',
                  color: 'var(--primary-color)',
                  fontWeight: 'bold',
                }}>
                {t(
                  'manageLabels.deleteModal.viewAssignedStation',
                  'View Assigned Stations'
                )}
              </Link>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <div>
                <Button
                  basic
                  content={t('common.close', 'Close')}
                  onClick={() => setIsModalOpen(false)}
                />

                <Button
                  disabled={!writeAccess}
                  content={t('common.delete', 'Delete')}
                  negative
                  onClick={() => {
                    onSubmitHandler(item);
                    setIsModalOpen(false);
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
}
