import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Header,
  Message,
  Modal,
  Table,
  Popup,
  TableHeader,
  TableBody,
  TableCell,
  Checkbox,
} from 'semantic';
import * as Yup from 'yup';
import { get } from 'lodash-es';

import { useTranslation } from 'react-i18next';
import { request, joiErrorDetailsToObject } from 'utils/api';
import { Formik, FieldArray } from 'formik';
import SelectField from 'components/form-fields/formik/SelectField';
import InputField from 'components/form-fields/formik/InputField';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import TagsField from 'components/form-fields/formik/TagsField';
import { SUPPORTED_CURRENCIES } from 'components/Layout/utils';
import HelpTip from 'components/HelpTip';
import FeatureFlag from 'components/FeatureFlag';
import { TFunction } from 'i18next';
import PlatformModule from 'components/PlatformModule';
import { useUser } from 'contexts/user';
import { BillingPlan, BillingPlanPrice } from 'types/billingplan';
import { useFeatures } from 'contexts/features';

interface ProviderCurrency {
  currency: string;
  active: boolean;
}

const typeOptions = [
  { text: 'Charge Station', value: 'cpo', key: 'cpo' },
  { text: 'Consumer Card', value: 'msp', key: 'msp' },
];

const billingPeriods = (t: TFunction) => [
  {
    key: 'month',
    value: 'month',
    text: t('editBillingPlan.billingPeriodMonth', 'month'),
  },
  {
    key: 'year',
    value: 'year',
    text: t('editBillingPlan.billingPeriodYear', 'year'),
  },
];

interface PricesFieldProps {
  name: string;
  currencies: ProviderCurrency[];
  billingPlanId?: string;
}

const FeesField = ({ name }: PricesFieldProps) => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();

  return (
    <FieldArray
      name={name}
      render={(helpers) => {
        const values: BillingPlanPrice[] = get(helpers.form.values, name);

        return (
          <>
            <Table style={{ tableLayout: 'fixed' }}>
              <TableHeader>
                {hasFeature('multi_currency_support') && (
                  <Table.HeaderCell width={6}>Currency</Table.HeaderCell>
                )}
                {helpers.form.values.usingBillingPlanFeesPercentage && (
                  <Table.HeaderCell width={6}>
                    {t(
                      'editBillingPlan.perKwhPercentage',
                      'Fee Per Kwh Percentage'
                    )}
                  </Table.HeaderCell>
                )}
                {!helpers.form.values.usingBillingPlanFeesPercentage && (
                  <Table.HeaderCell width={6}>
                    {t('editBillingPlan.perKwhFixed', 'Fee Per Kwh')}
                  </Table.HeaderCell>
                )}
                {/*
                  <Table.HeaderCell width={4}>
                    {t('editBillingPlan.perSession', 'Fee Per Session')}
                  </Table.HeaderCell>
                */}
              </TableHeader>
              <TableBody>
                {values?.map((price: BillingPlanPrice, i: number) => {
                  return (
                    <Table.Row key={i}>
                      {hasFeature('multi_currency_support') && (
                        <TableCell>
                          <PlatformModule moduleName="multi-currency">
                            {price.currency}
                          </PlatformModule>
                        </TableCell>
                      )}
                      {helpers.form.values.usingBillingPlanFeesPercentage && (
                        <TableCell>
                          <InputField
                            name={`${name}.${i}.perKwhPercentage`}
                            type="number"
                            min={0}
                            max={50}
                            step={0.1}
                            hideErrorLabel
                          />
                        </TableCell>
                      )}
                      {!helpers.form.values.usingBillingPlanFeesPercentage && (
                        <TableCell>
                          <InputField
                            name={`${name}.${i}.perKwhFixed`}
                            type="number"
                            min={0}
                            max={1}
                            step={0.1}
                            hideErrorLabel
                          />
                        </TableCell>
                      )}
                      {/*
                      <TableCell>
                        <InputField
                          name={`${name}.${i}.perSession`}
                          type="number"
                          min={0}
                          max={1}
                          step={0.01}
                          hideErrorLabel
                        />
                      </TableCell>
                      */}
                    </Table.Row>
                  );
                })}
              </TableBody>
            </Table>

            <div>
              <Checkbox
                as=""
                label={t(
                  'editBillingPlan.usePercentagesForFees',
                  'Use Percentages'
                )}
                checked={!!helpers.form.values.usingBillingPlanFeesPercentage}
                onChange={(_: any, data: any) => {
                  helpers.form.setFieldValue(
                    `usingBillingPlanFeesPercentage`,
                    data.checked
                  );
                }}
              />
            </div>
            <br />
          </>
        );
      }}
    />
  );
};

const PricesField: React.FC<PricesFieldProps> = ({
  name,
  currencies,
  billingPlanId,
}) => {
  const [currenciesInUse, setCurrenciesInUse] = useState<string[]>([]);

  const fetchCurrenciesInUse = async () => {
    const { data } = await request({
      method: 'GET',
      path: `/1/billing-plans/${billingPlanId}/currencies-in-use`,
    });
    if (Array.isArray(data)) {
      setCurrenciesInUse(data);
    }
  };

  useEffect(() => {
    billingPlanId && fetchCurrenciesInUse();
  }, []);

  const { t } = useTranslation();
  const { hasFeature } = useFeatures();
  return (
    <FieldArray
      name={name}
      render={(helpers) => {
        const values = get(helpers.form.values, name);
        const formType = helpers.form.values.providerContext;
        return (
          <>
            <Table style={{ tableLayout: 'fixed' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>{t('common.currency', 'Currency')}</span>
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    <span>{t('editBillingPlan.perPeriod', 'per Period')}</span>{' '}
                  </Table.HeaderCell>
                  {formType === 'cpo' && (
                    <Table.HeaderCell width={2}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                          {t('editBillingPlan.perDevice', 'Per Device')}
                        </span>{' '}
                        <HelpTip
                          title={t('editBillingPlan.perDeviceTitle', 'Device')}
                          text={t(
                            'editBillingPlan.perDeviceText',
                            'The price "per device" refers to a one-time fee that is charged when a new charge station is added by an account. This is only applicable when configuring a Charge Station billing plan.'
                          )}
                        />
                      </div>
                    </Table.HeaderCell>
                  )}
                  {formType === 'msp' && (
                    <Table.HeaderCell width={2}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{t('editBillingPlan.perCard', 'Per Card')}</span>{' '}
                        <HelpTip
                          title={t('editBillingPlan.perCardTitle', 'Card')}
                          text={t(
                            'editBillingPlan.perCardText',
                            'The price "per card" refers to a one-time fee that is charged when a new card is issued. This is only applicable when configuring a Consumer Card billing plan.'
                          )}
                        />
                      </div>
                    </Table.HeaderCell>
                  )}
                  {formType === 'msp' && (
                    <Table.HeaderCell width={2}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                          {t('editBillingPlan.perSession', 'Per Session')}
                        </span>{' '}
                      </div>
                    </Table.HeaderCell>
                  )}
                  <FeatureFlag feature="multi_currency_support">
                    <PlatformModule moduleName="multi-currency">
                      <Table.HeaderCell width={1}></Table.HeaderCell>
                    </PlatformModule>
                  </FeatureFlag>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {values?.map((price: BillingPlanPrice, i: number) => {
                  const currencyLabel = SUPPORTED_CURRENCIES.find(
                    (x) => x.value === price.currency
                  );
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>
                        {hasFeature('multi_currency_support') ? (
                          <PlatformModule moduleName="multi-currency">
                            <SelectField
                              name={`${name}.${i}.currency`}
                              options={currencies?.map((x) => ({
                                key: x.currency,
                                text: `${x.currency} (${
                                  x.active ? 'active' : 'inactive'
                                })`,
                                value: x.currency,
                              }))}
                              hideErrorLabel
                            />
                          </PlatformModule>
                        ) : (
                          <p>{currencyLabel?.text}</p>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <InputField
                          name={`${name}.${i}.perPeriod`}
                          type="number"
                          min={0}
                          step={0.01}
                          hideErrorLabel
                        />
                      </Table.Cell>
                      {formType === 'cpo' && (
                        <Table.Cell>
                          <InputField
                            name={`${name}.${i}.perDevice`}
                            type="number"
                            min={0}
                            disabled={formType === 'msp'}
                            step={0.01}
                            hideErrorLabel
                          />
                        </Table.Cell>
                      )}
                      {formType === 'msp' && (
                        <Table.Cell>
                          <InputField
                            name={`${name}.${i}.perCard`}
                            type="number"
                            min={0}
                            disabled={formType === 'cpo'}
                            step={0.01}
                            hideErrorLabel
                          />
                        </Table.Cell>
                      )}
                      {formType === 'msp' && (
                        <Table.Cell>
                          <InputField
                            name={`${name}.${i}.perSession`}
                            disabled={formType === 'cpo'}
                            type="number"
                            min={0}
                            step={0.01}
                            hideErrorLabel
                          />
                        </Table.Cell>
                      )}
                      <PlatformModule moduleName="multi-currency">
                        <FeatureFlag feature="multi_currency_support">
                          <Table.Cell>
                            <Popup
                              disabled={
                                values.length !== 1 &&
                                !currenciesInUse.includes(price.currency)
                              }
                              content={
                                values.length === 1
                                  ? t(
                                      'editBillingPlan.lastCurrencyCannotBeDeleted',
                                      'Billing plan should contain at least one currency.'
                                    )
                                  : t(
                                      'editBillingPlan.currencyCannotBeDeletedAccountRef',
                                      'Currency cannot be deleted as it is in use by at least one account.'
                                    )
                              }
                              trigger={
                                <span>
                                  <Button
                                    as="button"
                                    icon="trash"
                                    disabled={
                                      values.length === 1 ||
                                      currenciesInUse.includes(price.currency)
                                    }
                                    onClick={async () => {
                                      helpers.remove(i);
                                    }}
                                  />
                                </span>
                              }
                            />
                          </Table.Cell>
                        </FeatureFlag>
                      </PlatformModule>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </>
        );
      }}
    />
  );
};

interface Props {
  close(): void;
  onSave(): void;
  onSubmit(billingPlan: BillingPlan): Promise<void>;
  billingPlan: Partial<BillingPlan>;
}

const BillingPlanForm: React.FC<Props> = ({
  billingPlan,
  close,
  onSave,
  onSubmit,
}) => {
  const { t } = useTranslation();
  // @ts-ignore
  const { provider } = useUser();

  const isUpdate = !!billingPlan?.id;

  if (billingPlan.prices?.length) {
    // @ts-ignore This is not part of the spec but I'm using it to internalize inital values for the toggle here
    billingPlan.usingBillingPlanFeesPercentage =
      billingPlan.prices[0]?.perKwhPercentage > 0;
  }

  const submitHandler = async (formValues: any, formikBag: any) => {
    // depending on the type of billing plan, we want to reset the values of the other fields
    const prices = formValues.prices.map((price: any) => {
      if (formValues.providerContext === 'cpo') {
        price.perCard = 0;
        price.perSession = 0;
      } else {
        price.perDevice = 0;
      }

      if (formValues.usingBillingPlanFeesPercentage) {
        price.perKwhFixed = 0;
      } else {
        price.perKwhPercentage = 0;
      }

      delete formValues.usingBillingPlanFeesPercentage;

      return price;
    });

    const body = {
      ...formValues,
      prices,
    };

    if (formValues.usingBillingPlanFeesPercentage) {
      formValues;
    }

    try {
      await onSubmit(body);
      close();
      onSave();
    } catch (error: any) {
      if (Array.isArray(error?.details)) {
        formikBag.setErrors(joiErrorDetailsToObject(error));
      } else {
        formikBag.setStatus(error?.message);
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount
        initialTouched={{
          providerContext: true,
          billingPeriod: true,
          priority: true,
          prices: true,
          details: {
            en: {
              name: true,
            },
          },
        }}
        onSubmit={(formValues, formikBag) =>
          submitHandler(formValues, formikBag)
        }
        validationSchema={Yup.object({
          providerContext: Yup.string().oneOf(['cpo', 'msp']).required(),
          billingPeriod: Yup.string().oneOf(['month', 'year']).required(),
          priority: Yup.number().min(0).required(),
          prices: Yup.array().of(
            Yup.object({
              currency: Yup.string().required(),
              perPeriod: Yup.number().min(0).required(),
              perDevice: Yup.number().min(0).default(0),
              perCard: Yup.number().min(0).default(0),
              perSession: Yup.number().min(0).default(0),
            })
          ),
          details: Yup.object({
            en: Yup.object({
              name: Yup.string().required(),
            }),
          }),
        })}
        initialValues={{
          details: {
            en: {
              name: 'Untitled',
            },
          },
          ...billingPlan,
        }}>
        {({ status, values, dirty, isSubmitting, handleSubmit, isValid }) => {
          const url = `${document.location.protocol}//${document.location.host}/join?journey=${values.providerContext || 'generic'}&billingPlanId=${billingPlan.id}`;
          const [currencies, setCurrencies] = useState<ProviderCurrency[]>([]);

          const fetchCurrencies = async () => {
            const { data } = await request({
              method: 'GET',
              path: `/1/providers/${provider!.id}/currencies`,
            });
            if (Array.isArray(data)) {
              setCurrencies(data);
            }
          };

          useEffect(() => {
            fetchCurrencies();
          }, []);

          return (
            <>
              <Modal.Header role={'heading'}>
                {isUpdate
                  ? t(
                      'editBillingPlan.titleEdit',
                      'Edit Billing Plan {{name}}',
                      {
                        name: billingPlan.name,
                      }
                    )
                  : t('editBillingPlan.titleNew', 'New Billing Plan')}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <Form.Group widths="equal">
                    <SelectField
                      label="Type"
                      required
                      name="providerContext"
                      options={typeOptions}
                    />
                    <SelectField
                      label={t(
                        'editBillingPlan.billingPeriod',
                        'Billing Period'
                      )}
                      required
                      name="billingPeriod"
                      options={billingPeriods(t)}
                    />
                  </Form.Group>
                  <Header>{t('editBillingPlan.prices', 'Prices')}</Header>
                  <PricesField name="prices" currencies={currencies} />
                  {values.providerContext === 'cpo' && (
                    <PlatformModule moduleName="white-label-fees">
                      <Header>
                        {t('editBillingPlan.usageFees', 'Usage Fees')}
                      </Header>
                      <FeesField
                        name="prices"
                        billingPlanId={billingPlan?.id}
                        currencies={currencies}
                      />
                    </PlatformModule>
                  )}
                  <PlatformModule moduleName="multi-currency">
                    <FeatureFlag feature="multi_currency_support">
                      {currencies.length > (values?.prices?.length ?? 0) && (
                        <Button
                          as="button"
                          icon="plus"
                          role="button"
                          label={t('editBillingPlan.addPrice', 'Add currency')}
                          onClick={() =>
                            values?.prices?.push({
                              currency: '',
                              perPeriod: 0,
                              perCard: 0,
                              perDevice: 0,
                              perSession: 0,
                            })
                          }
                        />
                      )}
                    </FeatureFlag>
                  </PlatformModule>
                  <Header>{t('editBillingPlan.options', 'Options')}</Header>
                  <Form.Group>
                    <CheckboxField
                      label={t(
                        'editBillingPlan.visibleToAll',
                        'Is visible to all users?'
                      )}
                      name="isPublic"
                    />
                    {values.providerContext === 'msp' && (
                      <CheckboxField
                        label={t(
                          'editBillingPlan.isPrimary',
                          'Make this billing plan appear on signup'
                        )}
                        name="isPrimary"
                      />
                    )}
                  </Form.Group>
                  <InputField
                    name="priority"
                    type="number"
                    label="Display priority"
                  />

                  <TagsField
                    label={t('editBillingPlan.tags', 'Tags')}
                    name="tags"
                  />

                  <Header>
                    {t('editBillingPlan.detailsEnglish', 'Details English')}
                  </Header>
                  <InputField
                    label={t('editBillingPlan.name', 'Name')}
                    name="details.en.name"
                    required
                  />
                  <InputField
                    name="details.en.descriptionLong"
                    label={t(
                      'editBillingPlan.descriptionLong',
                      'Description Long'
                    )}
                  />
                  <TagsField
                    label={t('editBillingPlan.features', 'Features')}
                    name="details.en.features"
                  />

                  <Header>
                    {t('editBillingPlan.detailsFrench', 'Details French')}
                  </Header>
                  <InputField
                    label={t('editBillingPlan.name', 'Name')}
                    name="details.fr.name"
                  />
                  <InputField
                    name="details.fr.descriptionLong"
                    label={t(
                      'editBillingPlan.descriptionLong',
                      'Description Long'
                    )}
                  />
                  <TagsField
                    label={t('editBillingPlan.features', 'Features')}
                    name="details.fr.features"
                  />

                  <Header>
                    {t('editBillingPlan.detailsDutch', 'Details Dutch')}
                  </Header>
                  <InputField
                    label={t('editBillingPlan.name', 'Name')}
                    name="details.nl.name"
                  />
                  <InputField
                    name="details.nl.descriptionLong"
                    label={t(
                      'editBillingPlan.descriptionLong',
                      'Description Long'
                    )}
                  />
                  <TagsField
                    label={t('editBillingPlan.features', 'Features')}
                    name="details.nl.features"
                  />

                  <Header>
                    {t('editBillingPlan.detailsGerman', 'Details German')}
                  </Header>
                  <InputField
                    label={t('editBillingPlan.name', 'Name')}
                    name="details.de.name"
                  />
                  <InputField
                    name="details.de.descriptionLong"
                    label={t(
                      'editBillingPlan.descriptionLong',
                      'Description Long'
                    )}
                  />
                  <TagsField
                    label={t('editBillingPlan.features', 'Features')}
                    name="details.de.features"
                  />

                  <Header>
                    {t('editBillingPlan.detailsItalian', 'Details Italian')}
                  </Header>
                  <InputField
                    label={t('editBillingPlan.name', 'Name')}
                    name="details.it.name"
                  />
                  <InputField
                    name="details.it.descriptionLong"
                    label={t(
                      'editBillingPlan.descriptionLong',
                      'Description Long'
                    )}
                  />
                  <TagsField
                    label={t('editBillingPlan.features', 'Features')}
                    name="details.it.features"
                  />

                  <Header>
                    {t('editBillingPlan.detailsSpanish', 'Details Spanish')}
                  </Header>
                  <InputField
                    label={t('editBillingPlan.name', 'Name')}
                    name="details.es.name"
                  />
                  <InputField
                    name="details.es.descriptionLong"
                    label={t(
                      'editBillingPlan.descriptionLong',
                      'Description Long'
                    )}
                  />
                  <TagsField
                    label={t('editBillingPlan.features', 'Features')}
                    name="details.es.features"
                  />

                  {isUpdate && values?.providerContext === 'msp' && (
                    <Message info>
                      <Message.Header>
                        {t(
                          'editBillingPlan.billingPlanUrl',
                          'Billing Plan URL'
                        )}
                      </Message.Header>
                      <p>
                        {t(
                          'editBillingPlan.billingPlanDescription',
                          'In order to restrict any new signups to this Billing Plan, use the following link:'
                        )}{' '}
                        <a target="_blank" href={url} rel="noopener noreferrer">
                          {url}
                        </a>
                      </p>
                    </Message>
                  )}
                  {status && (
                    <Message error>
                      <p>{status}</p>
                    </Message>
                  )}
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  as="button"
                  loading={isSubmitting}
                  role="button"
                  aria-label={
                    isUpdate ? t('button.update') : t('button.create')
                  }
                  primary
                  disabled={isSubmitting || !isValid || !dirty}
                  content={isUpdate ? t('button.update') : t('button.create')}
                  onClick={handleSubmit}
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </>
  );
};

BillingPlanForm.defaultProps = {
  billingPlan: {
    billingPeriod: 'month',
    isPublic: false,
    priority: 0,
    providerContext: 'cpo',
    id: '',
    name: '',
    prices: [
      {
        currency: 'EUR',
        perPeriod: 0,
        perDevice: 0,
        perSession: 0,
        perCard: 0,
      },
    ],
  },
};

export default BillingPlanForm;
