import React from 'react';

import { Button, Icon } from 'semantic';

type Props = {
  selected: boolean;
  onClick?: (selected: boolean) => void;
  children: React.ReactNode;
  disabled?: boolean;
};

export function SelectableButton({
  children,
  selected,
  onClick,
  disabled,
}: Props) {
  const selectionIcon = selected ? 'circle-check' : 'circle regular';

  return (
    <Button
      disabled={disabled}
      basic
      color={selected ? 'blue' : null}
      style={{ width: '100%' }}
      onClick={(e: React.SyntheticEvent<HTMLElement>) => {
        onClick && onClick(selected);
      }}>
      <div style={{ float: 'left', paddingRight: 10 }}>{children}</div>
      <div style={{ float: 'right' }}>
        <Icon name={selectionIcon} />
      </div>
    </Button>
  );
}
