import { request } from 'utils/api';

import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Segment,
  Divider,
  Table,
  Button,
  Loader,
  Dropdown,
} from 'semantic-ui-react';
import { BillingEntity, TaxResidencyCountryCode } from '../types';
import BillingEntityModal from './Modal';

import {
  Breadcrumbs,
  Layout,
  Search,
  SearchFilters,
  ListHeader,
  Confirm,
} from 'components';
import { mapEnumToOptions } from '../utils';
import InspectObject from '../../../components/modals/InspectObject';
import { currentUserCanAccess } from '../../../utils/roles';

export default function TableView() {
  const { t } = useTranslation();
  const searchRef = useRef(null);

  const onDataNeeded = async (filters: any) => {
    return await request({
      path: '/1/billing-entities/search',
      method: 'POST',
      body: filters,
    });
  };

  const labels = {
    taxResidencyCountryCode: t(
      'billingEntities.taxResidencyCountryCode',
      'Tax Residency Country Code'
    ),
    createdAt: t('common.createdAt'),
    deleted: t('billingEntities.deleted', 'Deleted'),
  };

  const getFilterMapping = () => {
    return {
      taxResidencyCountryCode: {
        name: 'taxResidencyCountryCode',
        label: t('accounts.taxResidencyCountryCode'),
        type: 'dropdown' as 'dropdown',
      },
      deleted: {
        name: 'deleted',
        label: t('billingEntities.deleted', 'Deleted'),
        type: 'boolean' as 'boolean',
      },
    };
  };

  const taxResidencyCountryOptions = mapEnumToOptions(TaxResidencyCountryCode);
  const writeAccess = currentUserCanAccess('system/billing-entities', 'write');

  return (
    <Search.Provider
      ref={searchRef}
      onDataNeeded={onDataNeeded}
      filterMapping={getFilterMapping()}>
      {() => {
        return (
          <>
            <Breadcrumbs
              active={t('bilingEntities.title', 'Billing Entities')}
            />
            <ListHeader title={t('billingEntities.title', 'Billing Entities')}>
              <BillingEntityModal
                isUpdate={false}
                onSave={() => searchRef.current?.reload()}
                trigger={
                  <Button
                    primary
                    floated="right"
                    style={{ marginTop: '-5px' }}
                    content={t(
                      'billingEntities.createNew',
                      'New Billing Entity'
                    )}
                    icon="plus"
                    disabled={!writeAccess}
                  />
                }
              />
            </ListHeader>

            <Segment>
              <Layout horizontal spread stackable>
                <SearchFilters.ModalFilterV2>
                  <SearchFilters.Dropdown
                    label={labels.taxResidencyCountryCode}
                    name="taxResidencyCountryCode"
                    options={taxResidencyCountryOptions}
                  />

                  <SearchFilters.Checkbox
                    label={labels.deleted}
                    name="deleted"
                  />
                </SearchFilters.ModalFilterV2>

                <Layout horizontal stackable center right>
                  <Search.Total />
                </Layout>
              </Layout>
            </Segment>

            <Search.Status noResults={t('common.noResults', 'No Results')} />
            <DataTable writeAccess={writeAccess} />
            <Divider hidden />

            <Search.Pagination />
          </>
        );
      }}
    </Search.Provider>
  );
}

function DataTable({ writeAccess }: { writeAccess: boolean }) {
  const { t } = useTranslation();
  const { items, reload, loading, error } = useContext(Search.Context) as any;

  if (error) {
    throw new Error(error.message);
  }

  if (loading) {
    return <Loader active />;
  }

  return (
    <>
      {items.length > 0 && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t('billingEntities.id', 'Id')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('billingEntities.name', 'Name')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t(
                  'billingEntities.taxResidencyCountryCode',
                  'Tax Residency Country Code'
                )}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('billingEntities.deleted', 'Deleted')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('billingEntities.columnActions', 'Actions')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {items?.map((item: BillingEntity) => (
              <Table.Row key={item.id}>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.taxResidencyCountryCode}</Table.Cell>
                <Table.Cell>{item.deleted ? 'Yes' : 'No'}</Table.Cell>
                <Table.Cell>
                  <BillingEntityModal
                    isUpdate={true}
                    billingEntity={item}
                    onSave={reload}
                    onClose={reload}
                    disabled={!writeAccess}
                    trigger={<Button basic icon="pen-to-square" title="Edit" />}
                  />

                  <Dropdown button basic text={t('common.more', 'More')}>
                    <Dropdown.Menu direction="left">
                      <Actions
                        name={item.name}
                        writeAccess={writeAccess}
                        item={item}
                        reload={reload}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

interface ActionsProps {
  name: string;
  writeAccess: boolean;
  item: BillingEntity;
  reload: () => void;
}
function Actions({ name, writeAccess, item, reload }: ActionsProps) {
  const { t } = useTranslation();

  const onDelete = (itemId: string) => {
    return request({
      method: 'DELETE',
      path: `/1/billing-entities/${itemId}`,
    });
  };

  return (
    <>
      {/* @ts-ignore */}
      <Confirm
        header={t(
          'deleteDialog.header',
          'Are you sure you want to delete {{name}}?',
          { name: name }
        )}
        content={t(
          'deleteDialog.content',
          'All data will be permanently deleted'
        )}
        trigger={
          <Dropdown.Item icon="trash" disabled={!writeAccess} text="Delete" />
        }
        onConfirm={async () => {
          await onDelete(item.id);
          reload();
        }}
        customErrorMessage={t(
          'billingEntities.deleteErrorCustomMessage',
          'Cannot delete a billing entity that is linked to a provider'
        )}
      />

      <InspectObject
        name="Billing Entity"
        data={item}
        trigger={<Dropdown.Item text="Inspect" icon="code" />}
      />
    </>
  );
}
