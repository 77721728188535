import React, { useContext } from 'react';
import { startCase } from 'lodash-es';
import { Divider, Message, Card, Transition, Button } from 'semantic';
import { Search } from 'components';
import styled from 'styled-components';
import { Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ExpandableJson from 'components/ExpandableJson';
import ViewCommand from 'components/modals/ViewCommand';
import { fromNow, formatDateTimeWithSeconds } from 'utils/date';
import { Command } from 'types/command';

function colorForItem(command) {
  if (command.error) {
    return 'red';
  }
  if (command.status === 'busy' && !command.result) {
    return 'yellow';
  }
  if (command.status === 'done' && command.result) {
    return 'olive';
  }
  return 'grey';
}

function colorForStatus(status) {
  if (status === 'pending' || status === 'busy') {
    return 'orange';
  }
  if (status === 'done') {
    return 'olive';
  }
  if (status === 'error' || status === 'timeout') {
    return 'red';
  }
  return undefined;
}

function formatOrigin(destination): { code: string; text: string } {
  if (destination === 'centralsystem') {
    return {
      code: 'evseControllers.commandsSentFromDevice',
      text: 'Sent From Device',
    };
  } else {
    return {
      code: 'evseControllers.commandsSentFromCloud',
      text: 'Sent From Cloud',
    };
  }
}

const ResultsDiv = styled.div`
  color: #6c727f;
  margin-bottom: 1em;
`;

const RightFloatingDiv = styled.div`
  float: right;
`;

type Props = {
  onToggleItem: (id: string, checked: boolean) => void;
};

export default function EvseControllerCommandsTable({ onToggleItem }: Props) {
  const { items, reload, loading, meta } = useContext(Search.Context);
  const { t } = useTranslation();
  const commands = items as Command[];

  if (!items.length) {
    return null;
  }

  return (
    <div className="list">
      <ResultsDiv>
        {meta.skip + 1} - {Math.min(meta.skip + meta.limit, meta.total)} of{' '}
        {meta.total} results
      </ResultsDiv>
      <Transition.Group
        animation="fade down"
        as={Card.Group}
        duration={600}
        divided="true">
        {commands.map((command) => {
          const hasResult =
            (command.result && Object.keys(command.result).length) ||
            command.error;
          const origin = formatOrigin(command.destination);

          return (
            <Card key={command.id} fluid color={colorForItem(command)}>
              <Card.Content>
                <RightFloatingDiv>
                  <ViewCommand
                    item={command}
                    onClose={() => reload()}
                    trigger={<Button basic icon="code" title="Inspect" />}
                  />
                  <Search.Export
                    content=""
                    filename={command.id}
                    body={{ ids: [command.id] }}
                  />
                </RightFloatingDiv>
                <Card.Header style={{ paddingBottom: 5 }}>
                  {command.method}
                </Card.Header>
                <Card.Meta>
                  {t('evseControllers.commandsCreated', 'Created')}:{' '}
                  {formatDateTimeWithSeconds(command.createdAt)} (
                  {fromNow(command.createdAt)})
                </Card.Meta>
                <Card.Description>
                  <ExpandableJson
                    object={command.params}
                    name="Params"
                    collapsed={1}
                    displayDataTypes={false}
                  />
                  {hasResult && (
                    <>
                      <Divider
                        horizontal
                        style={{
                          textTransform: 'none',
                          fontWeight: 'normal',
                          letterSpacing: 'normal',
                          marginTop: '8px',
                          marginBottom: '8px',
                        }}>
                        {startCase(command.destination)} Response:
                      </Divider>
                      {command.result && (
                        <ExpandableJson
                          object={command.result}
                          name="Response"
                        />
                      )}{' '}
                      {command.error && (
                        <p>
                          <Message
                            error
                            header={command.error.type}
                            content={command.error.message}
                          />
                          {Object.keys(command.error.details || {}).length >
                            0 && (
                            <ExpandableJson
                              object={command.error.details}
                              name="Error"
                              collapsed={1}
                              displayDataTypes={false}
                            />
                          )}
                        </p>
                      )}
                    </>
                  )}
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                {t(origin.code, origin.text)} &middot;{' '}
                {t('evseControllers.commandsStatus', 'Status')}:{' '}
                <Label color={colorForStatus(command.status)}>
                  {startCase(command.status)}
                </Label>
              </Card.Content>
            </Card>
          );
        })}
      </Transition.Group>
    </div>
  );
}
