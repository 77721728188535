import { useEffect, useMemo, useState } from 'react';
import { request } from '../../../utils/api';
import { DisallowedReason, DropDownOptions } from './types';
import { useTranslation } from 'react-i18next';
import { useBulkEditEvseControllersErrorFormatter } from './errors';
import EditEvseControllerRoamingSettings from '../EditEvseControllerRoamingSettings';
import EditEvseControllerLabels from '../EditEvseControllerLabels';
import React from 'react';

function useAccountOptions() {
  const [accountOptions, setAccountOptions] = useState<
    { value: string; label: string }[]
  >([]);

  useMemo(() => {
    const requestAccountData = async () => {
      const response = await request({
        path: '/1/accounts/search',
        method: 'POST',
        body: {},
      });

      setAccountOptions(
        response.data.map((account: any) => ({
          value: account.id,
          label: account.name,
        }))
      );
    };

    requestAccountData();
  }, []);

  return accountOptions;
}

export function useDropDownOptions(evseControllerIds: string[]) {
  const { t } = useTranslation();
  const validatorErrorFormatter = useBulkEditEvseControllersErrorFormatter();
  const accountOptions = useAccountOptions();

  // Defaulting the dropdown options to disabled and then updating them based on the response from the API validator
  const [dropDownOptions, setDropdownOptions] = useState<
    {
      name: DropDownOptions;
      label: string;
      isAllowed: boolean;
      disallowedReasons?: DisallowedReason[];
    }[]
  >([
    {
      name: 'accountId',
      label: t('editEvseControllersModal.account', 'Account'),
      isAllowed: false,
    },
    {
      name: 'scanToPay',
      label: t('editEvseControllersModal.scanToPay', 'Scan-to-Pay'),
      isAllowed: false,
    },
    {
      name: 'roaming',
      label: t(
        'editEvseControllersModal.roaming',
        'Roaming and Roaming settings'
      ),
      isAllowed: false,
    },
    {
      name: 'labels',
      label: t('editEvseControllersModal.label', 'Label'),
      isAllowed: false,
    },
    // To add more settings, first include it in backend validator
    // then add it here, ultimately update secondDropdownOptions
    // to know what to do for its related sub-option
  ]);

  const secondDropdownOptions: {
    [key: string]:
      | {
          componentType: 'dropdown' | 'toggle';
          onDataNeeded: () => { value: string; label: string }[];
          componentProps?: { [key: string]: any };
        }
      | {
          componentType: 'custom';
          component: (objectPath: string) => JSX.Element;
        };
  } = {
    accountId: {
      componentType: 'dropdown',
      onDataNeeded: () => accountOptions,
    },
    scanToPay: {
      componentType: 'toggle',
      onDataNeeded: () => [
        {
          value: 'optIn',
          label: t('editEvseControllersModal.optIn', 'Opt-in to “Scan-to-Pay”'),
        },
        {
          value: 'optOut',
          label: t(
            'editEvseControllersModal.optOut',
            'Opt-out of “Scan-to-Pay”'
          ),
        },
      ],
    },
    roaming: {
      componentType: 'custom',
      component: (objectPath: string) => (
        <EditEvseControllerRoamingSettings objectPath={objectPath} />
      ),
    },
    labels: {
      componentType: 'custom',
      component: (objectPath: string) => (
        <EditEvseControllerLabels objectPath={objectPath} />
      ),
    },
  };

  useEffect(() => {
    const getApiValidatedOptions = async () => {
      const response = await request({
        method: 'POST',
        path: '/1/evse-controllers/bulk/edit/options',
        body: {
          ids: evseControllerIds,
        },
      });

      setDropdownOptions(
        mergeApiValidatorResponseAndDropDownOptions(
          dropDownOptions,
          response.data,
          validatorErrorFormatter
        )
      );
    };

    if (evseControllerIds.length) {
      getApiValidatedOptions();
    }
  }, [evseControllerIds]);

  return {
    dropDownOptions,
    secondDropdownOptions,
  };
}

const mergeApiValidatorResponseAndDropDownOptions = (
  defaultDropDownOptions: {
    name: DropDownOptions;
    label: string;
    isAllowed: boolean;
  }[],
  validatedOptions: {
    name: string;
    isAllowed: boolean;
    failedConditions: { code: string; description: string }[];
  }[],
  validatorErrorFormatter: ReturnType<
    typeof useBulkEditEvseControllersErrorFormatter
  >
): {
  name: DropDownOptions;
  label: string;
  isAllowed: boolean;
  failedReasons?: { title: string; message: string }[];
}[] => {
  return defaultDropDownOptions.map((defaultDropDownOption) => {
    const validatedOption = validatedOptions.find(
      (validatedOption) => validatedOption.name === defaultDropDownOption.name
    );

    return {
      ...defaultDropDownOption,
      isAllowed: validatedOption?.isAllowed || false,
      ...(validatedOption?.failedConditions?.length && {
        disallowedReasons: validatedOption.failedConditions.map(
          validatorErrorFormatter
        ),
      }),
    };
  });
};
