import React, { useMemo } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import EditEvseControllersForm from './EditEvseControllers.form';

export default function EditEvseControllers({
  chargingStationIds,
  trigger,
  onDone,
}: {
  chargingStationIds: string[];
  trigger: React.ReactNode;
  onDone?: () => void;
}) {
  const queryClient = useMemo(() => {
    return new QueryClient();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <EditEvseControllersForm
        evseControllerIds={chargingStationIds}
        trigger={trigger}
        onDone={onDone}
      />
    </QueryClientProvider>
  );
}
