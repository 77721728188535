import './toggle.less';
import React from 'react';
import { Button } from 'semantic-ui-react';
import { Icon } from 'semantic';
import classNames from 'classnames';
import { FieldValidator, useField } from 'formik';

type ToggleProps = {
  id: string;
  name: string;
  icon?: string;
  label?: string;
  required?: boolean;
  validate?: FieldValidator;
  hideErrorLabel?: boolean;
  validateImmediately?: boolean;
};

export default function Toggle({
  id,
  name,
  icon,
  label,
  required,
  validate,
  hideErrorLabel,
  validateImmediately,
}: ToggleProps) {
  const [field, meta, helpers] = useField({ name, validate });
  const hasTouched = validateImmediately ? true : meta.touched;

  return (
    <Button
      required={required}
      onClick={() => helpers.setValue(id)}
      error={
        hasTouched && meta.error
          ? hideErrorLabel
            ? true
            : meta.error
          : undefined
      }
      toggle
      type="button"
      checked={field.value === id}
      basic
      color={field.value === id ? 'blue' : undefined}
      className={'type-button'}>
      {icon ||
        (label && (
          <div className={'type-label'}>
            {icon && <Icon size="large" name={icon} />}
            {label && <span>{label}</span>}
          </div>
        ))}
      <Icon
        size="large"
        className={classNames('selection-icon', {
          disabled: field.value !== id,
        })}
        name={field.value === id ? 'circle-dot regular' : 'circle regular'}
      />
    </Button>
  );
}
