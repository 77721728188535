import React, { useCallback, useEffect, useState } from 'react';
import { set } from 'lodash-es';
import { Message, Popup, Segment } from 'semantic-ui-react';
import { Icon } from 'semantic';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, Redirect, useHistory } from 'react-router-dom';
import { Breadcrumbs, ErrorMessage } from 'components';
import { request } from 'utils/api';
import { Link } from 'react-router-dom';
import {
  Container,
  Form,
  Table,
  Header,
  Divider,
  HeaderSubheader,
  Label,
  Button,
} from 'semantic';
import SelectionReviewModal from 'components/BulkActionsIsland/SelectionReviewModal';
import UploadsField from 'components/form-fields/Uploads';
import {
  EVSE_BULK_ACTIONS_BE_UPDATEFIRMWARE_PATH,
  CHARGING_STATIONS_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH,
} from '../utils';
import { useToast, ToastButtonLayout } from 'components/Toast';
import { EvseController } from 'types/evse-controller';
import TextareaFileDrop from 'components/form-fields/TextareaFileDrop';

const selectionReviewEqual = (a, b) => a.id === b.id;

const selectionReviewColumns = [
  {
    title: <Table.HeaderCell>OCPP ID</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.ocppIdentity}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>Account</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.account?.name || '-'}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>Vendor</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.bootInfo?.chargePointVendor || '-'}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>OCPP Version</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.ocppProtocolVersion || '-'}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>Firmware Version</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell textAlign="center">
        {item.bootInfo?.firmwareVersion || '-'}
      </Table.Cell>
    ),
  },
];

type LocationState = {
  evseControllers?: any[];
};

type FormError = {
  uploadInput: Error | null;
  signingCertificate: Error | null;
  signature: Error | null;
};

const initialFormError = {
  uploadInput: null,
  signingCertificate: null,
  signature: null,
};

type ViewState = {
  loading: boolean;
  apiError: Error | null;
  formError: FormError;
};

type bulkUpdateFirmwareRequest = {
  note: string;
  uploadId: string;
  chargingStationIds: string[];
  signedFirmware?: {
    signingCertificate: string;
    signature: string;
  };
};

export default function EvseControllersBackgroundJobsFirmwareUpdate() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [viewState, setViewState] = useState<ViewState>({
    loading: false,
    apiError: null,
    formError: initialFormError,
  });
  const [preferSecureUpdate, setPreferSecureUpdate] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const { evseControllers } = (location.state || {}) as LocationState;

  const [selectedItems, setSelectedItems] = useState(evseControllers || []);

  const [bulkUpdateFirmware, setBulkUpdateFirmware] =
    useState<bulkUpdateFirmwareRequest>({
      note: '',
      uploadId: '',
      chargingStationIds: selectedItems.map(
        (item) => item.ocppChargingStationId
      ),
    });

  const setField = (name, value, nullable = false) => {
    const parsedValue = value === '' && nullable ? null : value;
    setBulkUpdateFirmware(set({ ...bulkUpdateFirmware }, name, parsedValue));
  };

  const updatedSelectedItems = (items) => {
    setSelectedItems(items);
    setField(
      'chargingStationIds',
      items.map((item) => item.ocppChargingStationId)
    );
  };

  const setUpload = (uploads) => {
    setViewState((prev) => ({
      ...prev,
      formError: { ...prev.formError, uploadInput: null },
    }));
    setField('uploadId', uploads[0]?.id, true);
  };

  const setSigningCertificate = (value: string, nullable?: boolean) => {
    setViewState((prev) => ({
      ...prev,
      formError: { ...prev.formError, signingCertificate: null },
    }));
    setField('signedFirmware.signingCertificate', value, nullable);
  };

  const setSignature = (value: string, nullable?: boolean) => {
    setViewState((prev) => ({
      ...prev,
      formError: { ...prev.formError, signature: null },
    }));
    setField('signedFirmware.signature', value, nullable);
  };

  const submitForm = useCallback(
    async (event) => {
      event.preventDefault();
      setViewState({
        loading: true,
        apiError: null,
        formError: initialFormError,
      });

      let formError: FormError = initialFormError;
      if (!bulkUpdateFirmware.uploadId) {
        formError = {
          ...formError,
          uploadInput: new Error('Please upload a file'),
        };
      }
      if (preferSecureUpdate) {
        if (
          !bulkUpdateFirmware.signedFirmware ||
          !bulkUpdateFirmware.signedFirmware.signingCertificate
        ) {
          formError = {
            ...formError,
            signingCertificate: new Error('Please enter a signing certificate'),
          };
        }
        if (
          !bulkUpdateFirmware.signedFirmware ||
          !bulkUpdateFirmware.signedFirmware.signature
        ) {
          formError = {
            ...formError,
            signature: new Error('Please enter a signature'),
          };
        }
      }
      if (formError !== initialFormError) {
        setViewState({ loading: false, apiError: null, formError });
        return;
      }

      try {
        const { data } = await request({
          method: 'POST',
          path: EVSE_BULK_ACTIONS_BE_UPDATEFIRMWARE_PATH,
          body: bulkUpdateFirmware,
        });

        toast.info(
          <ToastButtonLayout
            buttonTo={`${EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH}/${data.id}`}
            buttonTitle={t(
              'evseControllersBackgroundJobs.successToastView',
              'View'
            )}>
            <Trans
              i18nKey="evseControllersBackgroundJobsFirmwareUpdate.toastSuccess"
              defaults="Job {{jobId}} <strong>Update Firmware</strong> is in progress. Go to Background Jobs page for details."
              values={{ jobId: data.id }}
            />
          </ToastButtonLayout>
        );
        setViewState({
          loading: false,
          apiError: null,
          formError: initialFormError,
        });
        history.push(CHARGING_STATIONS_FE_PATH);
      } catch (e: any) {
        setViewState({
          loading: false,
          apiError: e,
          formError: initialFormError,
        });
      }
    },
    [bulkUpdateFirmware]
  );

  const togglePreferSecureUpdate = () => {
    setPreferSecureUpdate((prev) => {
      if (!!prev) {
        setField('signedFirmware', null, true);
        setViewState((prev) => ({
          ...prev,
          formError: {
            ...prev.formError,
            signingCertificate: null,
            signature: null,
          },
        }));
      } else {
        setField('signedFirmware', { signingCertificate: '', signature: '' });
      }
      return !prev;
    });
  };

  return selectedItems.length === 0 ? (
    <Redirect to={CHARGING_STATIONS_FE_PATH} />
  ) : (
    <Container>
      <Breadcrumbs
        path={[
          <Link key="backgroundjobs" to={CHARGING_STATIONS_FE_PATH}>
            {t(
              'evseControllersBackgroundJobsFirmwareUpdate.breadcrumbsChargingStations',
              'Charging Stations'
            )}
          </Link>,
        ]}
        active={t(
          'evseControllersBackgroundJobsFirmwareUpdate.title',
          'Update Firmware'
        )}
      />
      <Header as="h2">
        {t(
          'evseControllersBackgroundJobsFirmwareUpdate.title',
          'Update Firmware'
        )}
      </Header>
      <Divider hidden />

      <Header as="h4">
        {t(
          'evseControllersBackgroundJobsFirmwareUpdate.descriptionTitle',
          'Description'
        )}
      </Header>
      <Message
        info
        content={t(
          'evseControllersBackgroundJobsFirmwareUpdate.descriptionContent',
          `Central System can notify a Charge Point that it needs to update its firmware.
          The Central System will send an UpdateFirmware request to instruct the Charge Point to install new firmware.
          The PDU will contain a date and time after which the Charge Point is allowed to retrieve the new firmware and the location from which the firmware can be downloaded.`
        )}
      />

      <Divider hidden />

      <Form error={Boolean(viewState.formError)} onSubmit={submitForm}>
        <div>
          <SelectionReviewModal
            isOpen={isReviewModalOpen}
            onSetIsOpen={setIsReviewModalOpen}
            selectedItems={selectedItems}
            onSetSelectedItems={updatedSelectedItems}
            selectionReviewColumns={selectionReviewColumns}
            equal={selectionReviewEqual}
          />
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsFirmwareUpdate.reviewChargingStationTitle',
              '1. Review your selected charging station'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsFirmwareUpdate.reviewChargingStationSubTitle',
                'The executed commands cannot be reversed.'
              )}
            </HeaderSubheader>
          </Header>
          <Segment>
            <Label
              style={{ padding: '0.95em', margin: 0 }}
              content={`${selectedItems?.length} ${t(
                'evseControllersBackgroundJobsFirmwareUpdate.chargingStations',
                'charging stations'
              )}`}
            />
            <Button
              basic
              content={t(
                'evseControllersBackgroundJobsFirmwareUpdate.showSelectedChargingStations',
                'Show Selected'
              )}
              onClick={() => setIsReviewModalOpen(true)}
              type="button"
              icon="list"
            />
          </Segment>
        </div>

        <Divider hidden />

        <div>
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsFirmwareUpdate.firmwareUpdateFileTitle',
              '2. Firmware Update file'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsFirmwareUpdate.firmwareUpdateFileSubTitle',
                'Choose the firmware file you want to use:'
              )}
            </HeaderSubheader>
          </Header>
          <UploadsField
            type="code"
            validationError={viewState.formError.uploadInput}
            name="firmwareUpload"
            placeholder={t(
              'evseControllersBackgroundJobsFirmwareUpdate.firmwareUpdateFilePlaceholder',
              'Drag and drop your files here, or click to select files to upload.'
            )}
            maxFiles={1}
            bigBox={true}
            onChange={(uploads) => {
              setUpload(uploads);
            }}
          />
          <div>
            <Header as="h4">
              {t(
                'evseControllersBackgroundJobsFirmwareUpdate.signedFirmwareTitle',
                '3. Security'
              )}
              <HeaderSubheader style={{ marginTop: '0.8em' }}>
                {t(
                  'evseControllersBackgroundJobsFirmwareUpdate.signedFirmwareSubTitle',
                  'If you prefer secure update, you can provide a signing certificate and a signature. In case of failure, the firmware update will be done without signature.'
                )}
              </HeaderSubheader>
            </Header>
            <Segment>
              <Form.Checkbox
                checked={preferSecureUpdate}
                onChange={togglePreferSecureUpdate}
                label={t(
                  'evseControllersBackgroundJobsFirmwareUpdate.preferSecureUpdateCheckbox',
                  'Prefer Secure Update'
                )}
              />
              {preferSecureUpdate && (
                <div>
                  <TextareaFileDrop
                    name="signingCertificate"
                    validationError={viewState.formError.signingCertificate}
                    onFileDrop={(content) =>
                      setSigningCertificate(
                        content.replaceAll(/\r\n/g, '\n'),
                        true
                      )
                    }
                    onChange={(_, { name, value }) =>
                      setSigningCertificate(value as string)
                    }
                    label={t(
                      'evseControllersBackgroundJobsFirmwareUpdate.signingCertificateLabel',
                      'Signing Certificate (PEM encoded X.509 certificate)'
                    )}
                  />
                  <TextareaFileDrop
                    name="signature"
                    validationError={viewState.formError.signature}
                    onFileDrop={(content) =>
                      setSignature(content.replaceAll(/\r\n/g, '\n'), true)
                    }
                    onChange={(_, { name, value }) =>
                      setSignature(value as string)
                    }
                    label={t(
                      'evseControllersBackgroundJobsFirmwareUpdate.signatureLabel',
                      'Signature'
                    )}
                  />
                </div>
              )}
            </Segment>
          </div>
        </div>

        <Divider hidden />

        <div>
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsFirmwareUpdate.customNoteTitle',
              '4. Add custom note'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsFirmwareUpdate.customNoteSubTitle',
                'Write a note to show together with the details of this command at the Background Jobs page.'
              )}
            </HeaderSubheader>
          </Header>
          <Segment>
            <Form.Input
              name="note"
              placeholder={t(
                'evseControllersBackgroundJobsFirmwareUpdate.addCustomNotePlaceholder',
                'Type here...'
              )}
              label={t(
                'evseControllersBackgroundJobsFirmwareUpdate.addCustomNoteInputLabel',
                'Note'
              )}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
          </Segment>
        </div>

        <Divider />

        <ErrorMessage error={viewState.apiError} />

        <Button
          loading={viewState.loading}
          disabled={viewState.loading}
          content={t(
            'evseControllersBackgroundJobsFirmwareUpdate.runUpdate',
            'Run Update'
          )}
          icon="play"
          type="submit"
        />

        <Link to={viewState.loading ? '#' : '/charging-stations'}>
          <Button
            disabled={viewState.loading}
            content={t(
              'evseControllersBackgroundJobsFirmwareUpdate.cancelUpdate',
              'Cancel'
            )}
            basic
            type="button"
          />
        </Link>
      </Form>
    </Container>
  );
}
